import React from 'react';

const TaxConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/admin/shipping',
            component: React.lazy(() => import('./Form'))
        }
    ]
};

export default TaxConfig;
